// src/client.js
import axios from 'axios';

const Client = axios.create({
  baseURL: "https://api.servidorjusticiaypaz.com/api/survey/surveyAll", // base URL of the API
  headers: {
    'Content-Type': 'application/json',
  },
});

export default Client;
import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";

const InfoContainer = L.Control.extend({
  initialize: function (options) {
    L.Util.setOptions(this, options);
    this.options.properties = options.properties;
  },

  onAdd: function (map) {
    this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
    this.update();
    return this._div;
  },
  
  update: function (properties) {
    if (properties !== undefined && properties !== null) {
        const mujeres = properties["M"]
        const hombres = properties["H"]
        this._div.innerHTML =
        "<h2>Número de afiliados por Sección electoral</h2>" +
        (properties
          ? "<b>" +
            properties.name +
            "</b><b><li>Padrón Eletoral de la Seccion Electoral: </b>" + properties["PADRON ELECTORAL TOTAL"] +  
            "</b><b><li>Mujeres: </b>" + mujeres +
            "</b><b><li>Hombres: </b>" + hombres 
          : "Posicionate en una sección electoral");        
    }
  },

  onRemove: function (map) {
    // Nothing to do here
  }
});

const InfoControlAfiliados = createControlComponent((props) => new InfoContainer(props));

InfoControlAfiliados.defaultProps = {
  position: "topleft",
};

export default InfoControlAfiliados;

import { GeoJSON, useMap } from "react-leaflet";
import afiliaciones_shape from "../data/geojson_afiliados_update.json"; //secciones electorales coyoacan
import { style } from "../utils/style";
import { useRef } from "react";
import L from "leaflet";

const AfiliacionesComponent = ({ infoRef }) => {
  const map = useMap();
  const geojsonRef = useRef();

  // Mouseover function
  const highlightFeature = (e) => {
    var layer = e.target;

    layer.setStyle({
      weight: 1,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    infoRef.current.update(layer.feature.properties);
  };

  // Define function for `mouseout`
  const resetHighlight = (e) => {
    geojsonRef.current.resetStyle(e.target);
    infoRef.current.update(null);
  };

  const zoomToFeature = (e) => {
    map.fitBounds(e.target.getBounds());
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature
    });
  };

  return (
    <GeoJSON
      data={afiliaciones_shape}
      style={style}
      onEachFeature={onEachFeature}
      ref={geojsonRef}
    />
  );
};

export default AfiliacionesComponent;

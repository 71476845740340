import secc_elec_shape from "../data/geojson_coyoacán.json"; //secciones electorales coyoacan

const quantile = (arr, q) => {
    const asc = arr => arr.sort((a, b) => a - b);
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
  };
  
function padronelectoral(campo){
    let array_pe =[]
    secc_elec_shape.features.map(function(elem){
        array_pe.push(Number(elem.properties[campo]))
    })
    const [min,q1,q2,q3,max] = [Math.min.apply(Math,array_pe),quantile(array_pe,0.25),quantile(array_pe,0.5),quantile(array_pe,0.75),Math.max.apply(Math,array_pe)]
    return [min,q1,q2,q3,max]
}
   
function getColorCount(feature,campo){
    var d = feature.properties[campo]
    if (d >= padronelectoral(campo)[0] & d <= padronelectoral(campo)[1]) { return "#1C1AAF" }
        else if (d >= padronelectoral(campo)[1] & d <= padronelectoral(campo)[2]) { return "#0066E7" }
        else if (d >= padronelectoral(campo)[2] & d <= padronelectoral(campo)[3]) { return "#FFDA13" }
        else if (d >= padronelectoral(campo)[3] & d <= padronelectoral(campo)[4]) { return "#F18009" }
   }
  // functions for styling
export function style(feature) {
    return {
      fillColor: getColorCount(feature,"PADRON ELECTORAL TOTAL"),
      weight: 2,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7
    };
}  
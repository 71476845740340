import React, { useState , useEffect, useCallback, useRef, useMemo } from 'react';
import socketIOClient from 'socket.io-client'
import logo from './logo.svg';
import './App.css';
import { MapContainer, TileLayer, GeoJSON, LayersControl, LayerGroup,Marker, Tooltip, Popup, FeatureGroup, Polygon} from 'react-leaflet'
import MarkerClusterGroup from "react-leaflet-cluster";
import { useMapEvents } from 'react-leaflet/hooks'
import { Icon } from "leaflet";
import L from 'leaflet';
import LayerControl, { GroupedLayer } from "./LayerControl";
import icon from 'leaflet/dist/images/marker-icon.png';
import "leaflet/dist/images/marker-shadow.png";
import secc_elec_shape from "./data/geojson_coyoacán.json"; //secciones electorales coyoacan
import afiliaciones_shape from "./data/geojson_afiliados_update.json"; //secciones electorales coyoacan
import axios from 'axios';
import useAxios from "axios-hooks";

import Client from './components/client';
import Client_coyo from './components/client_coyoacan';
import ShapeComponent from "./components/ShapeComponent";
import InfoControl from "./components/InfoControl";
import InfoControlAfiliados from "./components/InfoControlAfiliados";
import LegendControl from "./components/LegendControl";
import DataComponent from "./components/DataComponent";
import AfiliacionesComponent from "./components/AfiliacionesComponent";

/* import { MongoClient } from 'mongodb';

const uri = 'mongodb+srv://developer:skGaHer7KDkI3m3Z@justiciaypaz.dpwwnls.mongodb.net/'

const client = new MongoClient(uri); */

/* try {
  await client.connect();
  await listDatabases(client);
} catch (e) {
  console.error(e);
} */
const removeAccents = str =>
str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

function get_icon_lider(lider){
  //console.log(lider)
  if (removeAccents(lider)==="Jose Manuel Perez Najera".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png',
      })
    return myIcon
  } else if (removeAccents(lider)==="Margot Castro Leal".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png',
      })
    return myIcon
  } else if (removeAccents(lider)==="Alfredo Miguel Moran Moguel".toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Guillermo Montaño Garcia'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Ana Lidia Banuelos Diaz'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Norma Edith Martinez Guzman'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Rosa Icela Cardenas'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      })
    return myIcon
  } else if (removeAccents(lider)==='Francisco Javier Hernandez Figueroa'.toUpperCase()){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
      })
    return myIcon
  } else {
    console.log(lider,removeAccents(lider))
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png',
      })
    return myIcon
  }
}

// DEFINICIÓN DEL ICONO MARKER
function get_icon_color(status){
  if (status==="ABRIO PUERTA"){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png',
      })
    return myIcon
  } else if (status==="ENCUESTA REALIZADA"){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      })
    return myIcon
  } else if (status==="NO ABRIO PUERTA"){
      var myIcon = L.icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
        })
    return myIcon
  } else if (status==='Sin actividad'){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
      })
    return myIcon
  } else if (status==='ENCUESTA REALIZADA SIN AFILIACIÓN'){
    var myIcon = L.icon({
      iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
      })
    return myIcon
  }
}

export default function App() { // COMPONENTE DE LA APLICACIÓN PARA IMPORTAR: <App />
  
  const [promotor, setPromotor] = useState([]);
  const [afiliado, setAfiliado] = useState([]);

// WEBSOCKET IO
/*   useEffect(() => {
  const socket = socketIOClient(ENDPOINT);
  socket.on('initialData', (data) => setPromotor(data))
    },[]); */
  const fetchData = async () => {
    const result = await Client.get();
    const result_coyo = await Client_coyo.get();
    setPromotor(result);
    setAfiliado(result_coyo);
  };     
// AXIOS REQUEST API GET
  useEffect(()=>{
    setInterval(() => {
      fetchData();
    },5000)
  },[])
  var count_seccion_electoral = []
  afiliado.data?.map((item) => { 
    count_seccion_electoral.push(item.section)
    })
  
  var dicty_seccion_electoral = [];
  count_seccion_electoral.forEach(function(element) {
    dicty_seccion_electoral[element]=count_seccion_electoral.filter(x => x === element).length
  });

  const infoRef = useRef();
  const infoRefAfilia = useRef();
  return (
    <MapContainer center={[19.33,-99.15]} zoom={13} dragging={true} touchZoom={true} scrollWheelZoom={true} doubleClickZoom={false}>
      <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      <LegendControl />
      <ShapeComponent />
      <LayersControl position="topleft" collapsed={false}>
          <LayerGroup>
            <LayersControl.Overlay name="Padrón electoral">
              <InfoControl ref={infoRef} />
              <DataComponent infoRef={infoRef}/>
            </LayersControl.Overlay>
          </LayerGroup>
          <LayerGroup>
            <LayersControl.Overlay name="Personas Afliliadas">    
              <InfoControlAfiliados ref={infoRefAfilia} />
              <AfiliacionesComponent infoRef={infoRefAfilia}/>
            </LayersControl.Overlay>
          </LayerGroup>
          <LayerGroup>
            <LayersControl.Overlay checked name="Monitoreo Afiliados"> 
              <MarkerClusterGroup maxClusterRadius={150} spiderfyOnMaxZoom={true} showCoverageOnHover={true} > 
                {afiliado.data?.map((item) => { 
                  console.log(item)
                  return (
                    <Marker key={item._id} position={item.placeIndex} icon={get_icon_lider(item.lider)}>
                      <Popup direction="top" style={{ backgroundColor: "rgb(0, 255, 30)", color: "#222" }}>
                        {/* <span>{"lider: "+item.lider} <br /> {"Sección: "+item.id_sección} <br /> {item.lider}</span> */}
                        <div>
                        <b> {"Nombre del Afiliado: "}</b>{item.name+" "+item.last_name}
                        <br /> <b>{"Sección electoral: " }</b>{item.section}
                        <br /> <b>{"Afiliados en esta sección electoral: " }</b>{dicty_seccion_electoral[item.section]}
                        <br />
                        <br /> <b>{"Lider encargado: "}</b>{item.lider} 
                        <br /> <b>{"Fecha del registro: "}</b>{item.fecha_registro_afiliado}</div>
                      </Popup>
                    </Marker>
                  )}
                )}
              </MarkerClusterGroup>
            </LayersControl.Overlay>
          </LayerGroup>
          <LayersControl.Overlay checked name="Lideres"> 
            <LayerGroup>
              <MarkerClusterGroup maxClusterRadius={150} spiderfyOnMaxZoom={true} showCoverageOnHover={true}> 
                {promotor.data?.map((item) => { console.log(item)
                  return (
                    <Marker key={item.status} position={item.coordenada} icon={get_icon_color(item.status)}>
                      <Tooltip direction="top" style={{ backgroundColor: "rgb(0, 255, 30)", color: "#222" }}>
                      <span>
                          <b>{"Lider: "}</b>{item.promotor} 
                        <br /> <b>{"Último movimiento: "}</b>{item.timestamp}</span>                        
                      </Tooltip>
                    </Marker>
                  )}
                )}
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  );
}

import { GeoJSON, useMap } from "react-leaflet";
import { style } from "../utils/style";
import { useRef } from "react";
import L from "leaflet";
import secc_elec_shape from "../data/geojson_coyoacán.json"; //secciones electorales coyoacan

function fstyle(feature) {
    return {
      fillColor: '',
      weight: 1,
      color: "blue",
      dashArray: "",
      fillOpacity: 0
    };
}  
const ShapeComponent = ({ infoRef }) => {
    const map = useMap();
    const geojsonRef = useRef();
    // Mouseover function
    const highlightFeature = (e) => {
        var layer = e.target;

        layer.setStyle({
        weight: 1,
        color: "blue",
        dashArray: "",
        fillOpacity: 0
        });

        if (infoRef !== undefined){
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
        }
            infoRef.current.update(layer.feature.properties);
        }
    };

    // Define function for `mouseout`
    const resetHighlight = (e) => {
        if (infoRef !== undefined){
        geojsonRef.current.resetStyle(e.target);
            infoRef.current.update(null);
        }
    };

    const zoomToFeature = (e) => {
        map.fitBounds(e.target.getBounds());
    };

    const onEachFeature = (feature, layer) => {
        layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature
        });
    };
    const style_secc_elec={color:'blue', fillOpacity:0, dashArray: '0.5'}

    return (
        <GeoJSON
        data={secc_elec_shape}
        style={fstyle}
        onEachFeature={onEachFeature}
        ref={geojsonRef}
        />
    );
};

export default ShapeComponent;
